import { Button, Grid } from "@mui/material";
//
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import { VicuTable } from "./VicuTable";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import VICUService from "../../services/VICUService";
import { useState } from "react";
import { AddVicuDialog } from "./AddVicuDialog";

export const VicuManagement = () => {
    //const theme = useTheme();
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showDialog, setShowDialog] = useState(false);

    // Fetch all VICUs
    const {
        data: vicuList,
        loading: viculoading,
        error: vicuerror,
    } = useFetch({
        url: VICUService.getAllVICU(),
        access_token: cookies.access_token,
    });

    if (viculoading) {
        return <Loading />;
    }
    if (vicuerror) {
        return <ServerProblem />;
    }

    if (vicuList === null) {
        return <>No Device Groups have been defined</>;
    }

    const openDialog = () => {
        setShowDialog(true);
    };

    console.log("viculist is ", vicuList);
    return (
        <div>
            <AddVicuDialog
                openState={showDialog}
                setOpenState={setShowDialog}
            />

            <Grid item>
                <VicuTable viculist={vicuList} />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
                <Button variant="contained" onClick={openDialog}>
                    {t("add_monitorgroup")}
                </Button>
            </Grid>
        </div>
    );
};
