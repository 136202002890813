import { useContext, useEffect } from "react";
//import { useState, useRef } from "react";
//import { AlarmsContext } from "../context/AlarmsContext";
import * as CONSTANTS from "../utils/constants/constants";
import { UserContext } from "../context/UserContext";

interface Props {
    setAdminDataPacket: (data:any) => void;
}

const useAdminSocket = ({ setAdminDataPacket }: Props) => {
    //const alarmsCtx = useContext(AlarmsContext);
    const userCtx = useContext(UserContext);



    useEffect(() => {
        if (userCtx?.user?.organization === undefined) {
            return;
        }

        const socket_address = CONSTANTS.WS_HOST() + "ws/homepage-socket/" + userCtx?.user?.organization + "/";
        console.log ("Requesting websocket to", socket_address)
        const socket = new WebSocket (socket_address);

        socket.onmessage = (event) => {
            try {
                if (event.data !== "") {
                    //const ts = JSON.parse(event.data).message.timestamp;
                    //console.log ("--> Device message", ts)
                    let eventData = JSON.parse (event.data)
                    setAdminDataPacket (eventData);
                } else {
                    //setStaleData(true);
                    //if (isMounted) sortScores();
                }
            } catch (err: any) {
                console.error(err);
            }
        };

        socket.onopen = () => {
            // Send WS message about what trend data we want
            const streamRequest = {
                    type:         "admin-request",
                    opcode:       "get-status",
                    organization: userCtx?.user?.organization,
            };

            socket.send(JSON.stringify (streamRequest ));

        };

        return () => {
            console.log("Closing socket");
            socket.close();
            //isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

};

export default useAdminSocket;
