import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    Box,
    Select,
    InputLabel,
    MenuItem,

} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Organization } from "../../interfaces/Organization";
import {SENTIO_TYPES, SENTIO_AI_YES_NO} from "../../interfaces/Organization"

import "../VicuManagement/VicuManagement.css"
import OrganizationService from "../../services/OrganizationService";
import { IngestionServer } from "../../interfaces/IngestionServer";
import IngestionServerDropdown from './IngestionServerDropdown';


interface Props {
    targetOrg?: Organization ;
    openState: boolean;
    setOpenState: (show:boolean) => void;
    dialogMode: string;
    ingestionServers: IngestionServer[];
}

const MIN_HOTSTORE_DURATION   = 12;     // Hours
const MAX_HOTSTORE_DURATION   = 7 * 47; // Hours, one week
const HOTSTORE_DEFAULT_VAL    = 48;
const POSTGRES_PORT           = 5432;
const DEFAULT_DATA_RESOLUTION = 5 * 60; // 5 minutes
const DEFAULT_SENTIO_TYPE     = "standard";
const DEFAULT_AI_EXECUTION    = "N";

export const OrgDialog = ({targetOrg, openState, setOpenState, dialogMode, ingestionServers } : Props) => {
    //console.log ("OrgDialog:", targetOrg)


    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showSaveButton, setShowSaveButton] = useState (false);
    const [confirmDelete, setConfirmDelete] = useState (false);
    const [orgId, setOrgId] = useState <string> (targetOrg ? targetOrg.organization_id : "")

    const [orgName, setOrgName] = useState <string> (targetOrg ? targetOrg.organization_name : "")
    const [sentioType, setSentioType]             = useState <string>(targetOrg ? targetOrg?.sentio_type : DEFAULT_SENTIO_TYPE)
    const [adminEmail, setAdminEmail]             = useState <string>(targetOrg ? targetOrg?.admin_email : "")
    const [loginDomain, setLoginDomain]           = useState <string>(targetOrg ? targetOrg?.login_domain : "")
    const [hotstoreDuration, setHotstoreDuration] = useState <number>(targetOrg ? targetOrg?.hotstore_duration : HOTSTORE_DEFAULT_VAL)

    const [sentioTypeChanged, setSentioTypeChanged]             = useState (false);
    const [orgNameChanged, setOrgNameChanged]                   = useState (false);
    const [orgIdChanged, setOrgIdChanged]                       = useState (false);
    const [hotstoreDurationChanged, setHotstoreDurationChanged] = useState (false);
    const [adminEmailChanged, setAdminEmailChanged]             = useState (false);
    const [loginDomainChanged, setLoginDomainChanged]           = useState (false);

    const [dataResolution, setDataResolution]               = useState <number> (targetOrg ? targetOrg?.data_resolution : DEFAULT_DATA_RESOLUTION)
    const [dataResolutionChanged, setDataResolutionChanged] = useState (false);

    const [aiExecution, setAiExecution]               = useState <string> (targetOrg ? (targetOrg?.ai_execution ? "Y" : "N") : DEFAULT_AI_EXECUTION)
    const [aiExecutionChanged, setAiExecutionChanged] = useState (false);

    const [proxyServer, setProxyServer]               = useState <string> (targetOrg ? targetOrg?.proxy_device_server : "")
    const [proxyServerChanged, setProxyServerChanged] = useState (false);

    const [, setProxyServerPort]              = useState <number> (-1)
    const [proxyServerPortChanged, ] = useState (false);

    const [backingStoreHost, setBackingStoreHost]               = useState <string> (targetOrg ? targetOrg?.backingstore_host : "")
    const [backingStoreHostChanged, setBackingStoreHostChanged] = useState (false);

    const [backingStorePort, setBackingStorePort]               = useState <number> (targetOrg ? targetOrg?.backingstore_port : POSTGRES_PORT)
    const [backingStorePortChanged, setBackingStorePortChanged] = useState (false);

    const [hotStoreHost, setHotstoreHost]               = useState <string> (targetOrg ? targetOrg?.hotstore_host : "")
    const [hotStoreHostChanged, setHotStoreHostChanged] = useState (false);

    const [hotStorePort, setHotStorePort]               = useState <number> (targetOrg ? targetOrg?.hotstore_port : POSTGRES_PORT)
    const [hotStorePortChanged, setHotStorePortChanged] = useState (false);


    const [ingestionTopic, setIngestionTopic]               = useState <string> (targetOrg ? targetOrg?.ingestion_topic : "")
    const [ingestionTopicChanged, setIngestionTopicChanged] = useState (false);

    const [consumerGroup, setConsumerGroup]               = useState <string> (targetOrg ? targetOrg?.ingestion_group : "$Default")
    const [consumerGroupChanged, setConsumerGroupChanged] = useState (false);

    const [ingestionServer, setIngestionServer]               = useState <string> (targetOrg ? targetOrg?.ingestion_server : "")
    const [ingestionServerChanged, setIngestionServerChanged] = useState (false);

    const [ingestionKey, setIngestionKey]               = useState <string> (targetOrg ? targetOrg?.ingestion_key : "")
    const [ingestionKeyChanged, setIngestionKeyChanged] = useState (false);

    const [currentSelectedServer, setCurrentSelectedServer] = useState <string> (targetOrg?.ingestion_system || "manual")
    const [selectedServerChanged, setSelectedServerChanged] = useState (false);

    const theme = useTheme();



    const handleClose = () => {
        setOpenState (false);
        window.location.href="/sysmgmt"
    }
        

    const handleSaveChanges = () => {
        console.log ("Handle save changes:", dialogMode)

        if (dialogMode === "add") {
            const data = {
                "organization_id"   : orgId,
                "organization_name" : orgName,
                "admin_email" : adminEmail,
                "data_resolution" : dataResolution,
                "hotstore_duration" : hotstoreDuration,
                "ingestion_server" : ingestionServer,
                "ingestion_key" : ingestionKey,
                "ingestion_topic" : ingestionTopic,
                "ingestion_group" : consumerGroup,
                "backingstore_host" : backingStoreHost,
                "backingstore_port" : backingStorePort,
                "hotstore_host" : hotStoreHost,
                "hotstore_port" : hotStorePort,
                "ai_execution" : aiExecution,
                "sentio_type" : sentioType,
                "proxy_device_server" : proxyServer,
                "login_domain" : loginDomain,
                "ingestion_system" : currentSelectedServer
            }
    
            // Create a new organization
            OrganizationService.addOrganization (data, cookies.access_token)
            .then((response) => {
                console.log("Success:", response);
                setShowSaveButton (false);
                setOrgNameChanged (false);
                setOpenState (false);
                window.location.href = "/sysmgmt";
            })
            .catch((errorResponse) => {
                console.log("Error:", errorResponse);
                console.log("HTTP error code:", errorResponse.status);
                if (errorResponse.status === 409) {
                    alert ("An organization with id " + orgId + " already exists")
                }
            });

            return;
        } // if "add"


        // We have an EDIT
        if (!targetOrg) {
            return;
        }
        const data = {
            "organization_id"   : targetOrg?.organization_id,
            "organization_name" : orgName,
            "admin_email" : adminEmail,
            "data_resolution" : dataResolution,
            "hotstore_duration" : hotstoreDuration,
            "ingestion_server" : ingestionServer,
            "ingestion_key" : ingestionKey,
            "ingestion_topic" : ingestionTopic,
            "ingestion_group" : consumerGroup,
            "backingstore_host" : backingStoreHost,
            "backingstore_port" : backingStorePort,
            "hotstore_host" : hotStoreHost,
            "hotstore_port" : hotStorePort,
            "ai_execution" : aiExecution,
            "sentio_type" : sentioType,
            "proxy_device_server" : proxyServer,
            "login_domain" : loginDomain,
            "ingestion_system" : currentSelectedServer
        }
        console.log ("EDIT:", data)
        OrganizationService.editOrganization (data, cookies.access_token)
        .then((res: any) => {
            //console.log("result:", res);
            targetOrg.organization_name = orgName;
            setShowSaveButton (false);
            setOrgNameChanged (false);
            window.location.href = "/sysmgmt";

        })
        .catch((err: any) => {
            console.log(err);
        });
    } // handleSaveChanges

    useEffect (() => {
        var showSave = false;
        switch (dialogMode) {
            case "edit" : {
                showSave = sentioTypeChanged || adminEmailChanged || orgNameChanged || loginDomainChanged || 
                hotstoreDurationChanged || hotStoreHostChanged || hotStorePortChanged || dataResolutionChanged ||
                backingStoreHostChanged || backingStorePortChanged || proxyServerChanged || proxyServerPortChanged ||
                ingestionTopicChanged || consumerGroupChanged || ingestionServerChanged || ingestionKeyChanged || orgIdChanged ||
                selectedServerChanged
                break;
            }

            case "add" : {
                showSave = adminEmail !== '' && orgName !== '' && orgId !== '' && backingStoreHost !== '' && hotStoreHost !== '' && ingestionTopic !== '' && consumerGroup !== '';
                
                if (currentSelectedServer === "manual") {
                    showSave = showSave  && ingestionKey !== '' && ingestionServer !== '' 
                } 
                
                break;
            }
        }
        setShowSaveButton (showSave)

    }, [dialogMode, sentioTypeChanged, adminEmailChanged, orgNameChanged, loginDomainChanged, 
        hotstoreDurationChanged, hotStoreHostChanged, hotStorePortChanged, dataResolutionChanged,
        backingStoreHostChanged, backingStorePortChanged, proxyServerChanged, proxyServerPortChanged, selectedServerChanged,
        ingestionTopicChanged, consumerGroupChanged, ingestionServerChanged, ingestionKeyChanged, orgIdChanged,  currentSelectedServer,
        adminEmail, orgName, orgId, backingStoreHost, hotStoreHost, ingestionTopic, ingestionKey, ingestionServer, consumerGroup])



    const deleteVICU = () => {
        setConfirmDelete (true)
    };

    const cancelDelete = () => {
        setConfirmDelete (false)
    };

    const yesDelete = () => {
        // Really delete the VICU. Send a request to the back-end
        
        if (!targetOrg) {
            return;
        }

        const data = {
            "organization_id" : targetOrg.organization_id
        }
        OrganizationService.deleteOrganization (data, cookies.access_token)
        .then((res: any) => {
            setConfirmDelete (false)
            window.location.href = "/sysmgmt";
            console.log("Delete result: " , res);
        })
        .catch((err: any) => {
            console.log(err);
        });

    };
    
    const handleItemChange = (id: string, data: any) => {
        // eslint-disable-next-line
        const urlPattern = /^((https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?([\/\w.-]*)*\/?)?$/;
        // eslint-disable-next-line
        const addressPattern = /^(https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?(:\d{0,5})?([\/\w .-]*)*\/?$/;

        const stringPattern = /^[a-zA-Z0-9_-]*$/;
        switch (id) {

            case "orgId" : {
                const value: string = data;
                if (stringPattern.test(value) || value === '') {
                  setOrgId(value);
                }
            
                setOrgIdChanged (value !== targetOrg?.organization_id)
                break;
            }

            case "orgName" : {
                const val: string = data;
                setOrgName (val);
                setOrgNameChanged (val.trim() !== targetOrg?.organization_name)
                break;
            }

            case "adminEmail" : {
                const val: string = data;
                setAdminEmail (val);
                setAdminEmailChanged (val.trim() !== targetOrg?.admin_email)
                break;
            }

            case "loginDomain" : {
                const value: string = data;
                if (urlPattern.test(value) || value === '') {
                    setLoginDomain (value);
                }
                setLoginDomainChanged (value !== targetOrg?.login_domain)
                break;
            }

            case "sentioType" : {
                const val: string = data;
                setSentioType(val);
                setSentioTypeChanged (val !== targetOrg?.sentio_type)
                console.log (val !== targetOrg?.sentio_type)
                break;
            }

            case "hotStoreDuration" : {
                const value: number = Number (data as number);
                setHotstoreDuration(value);
                setHotstoreDurationChanged (value !== targetOrg?.hotstore_duration)
                break;
            }

            case "dataResolution" : {
                const value: number = Number (data as number);
                setDataResolution(value);
                setDataResolutionChanged (value !== targetOrg?.data_resolution)
                break;
            }

            case "aiExecution" : {
                const val: string = data;
                setAiExecution(val);
                setAiExecutionChanged (val !== targetOrg?.ai_execution)
                break;
            }

            case "proxyServer" : {
                const value: string = data;
                if (addressPattern.test(value) || value === '') {
                    setProxyServer (value);
                }
                setProxyServerChanged (value !== targetOrg?.proxy_device_server)
                break;
            }

            case "proxyServerPort" : {
                const value: number = Number (data as number);
                setProxyServerPort(value);
                //setProxyServerPortChanged (val !== targetOrg?.proxy_device_server_port)
                break;
            }

            case "backingStoreHost" : {
                const value: string = data;
                if (addressPattern.test(value) || value === '') {
                    setBackingStoreHost (value);
                }
                setBackingStoreHostChanged (value !== targetOrg?.backingstore_host)
                break;
            }

            case "backingStorePort" : {
                const value: number = Number (data as number);
                console.log ("VALUE IS ", value)
                setBackingStorePort(value);
                setBackingStorePortChanged (value !== targetOrg?.backingstore_port)
                break;
            }

            case "hotStoreHost" : {
                const value: string = data;
                if (addressPattern.test(value) || value === '') {
                    setHotstoreHost (value);
                }
                setHotStoreHostChanged (value !== targetOrg?.hotstore_host)
                break;
            }

            case "hotStorePort" : {
                const value: number = Number (data as number);
                setHotStorePort(value);
                setHotStorePortChanged (value !== targetOrg?.hotstore_port)
                break;
            }

            case "ingestionTopic" : {
                const value: string = data;
                if (stringPattern.test(value) || value === '') {
                    setIngestionTopic(value);
                  }
                setIngestionTopicChanged (value !== targetOrg?.ingestion_topic)
                break;
            }

            case "consumerGroup" : {
                const val: string = data;
                setConsumerGroup(val);
                setConsumerGroupChanged (val !== targetOrg?.ingestion_group)
                break;
            }

            case "ingestionServer" : {
                const value: string = data;
                if (addressPattern.test(value) || value === '') {
                    setIngestionServer(value);
                  }
                setIngestionServerChanged (value !== targetOrg?.ingestion_server)
                break;
            }

            case "ingestionKey" : {
                const val: string = data;
                setIngestionKey(val);
                setIngestionKeyChanged (val !== targetOrg?.ingestion_key)
                break;
            }
            case "IngestionSystem" : {
                const old_system = targetOrg?.ingestion_system ? targetOrg.ingestion_system : "manual"
                const val: string = data;
                setCurrentSelectedServer(val);
                setSelectedServerChanged (val !== old_system)
                //setIngestionKeyChanged (val !== targetOrg?.ingestion_key)
                break;
            }

        } // switch


    } // handleItemChange
   
    //console.log ("Org info:", orgInfo)
    return (
    <>
        <Dialog open={confirmDelete} >
            <DialogTitle> {t ("confirm_organization_delete") } </DialogTitle>
            <DialogContent>
                <Typography>{t("delete_organization_notification")}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                    <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes_delete") }</Button>
                </Grid>
            </DialogActions>
        </Dialog>


        <Dialog  open = {openState} onClose={handleClose}  maxWidth="lg" >
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Box>{t("org_administration")}</Box>
                    </Grid>

                    <Grid item>
                        <TextField
                            label={t("organization_id")}
                            id="org_id"
                            type=""
                            value={orgId}
                            onChange={(e) => handleItemChange("orgId", e.target.value)}
                            disabled = {targetOrg !== undefined}
                            InputLabelProps={{ shrink: true }}   
                            placeholder = "Enter unique ID"                 
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                        background: !orgIdChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                        />

                    </Grid>

                </Grid>
            </DialogTitle>


            <DialogContent>

            <Grid container item pt={3} mb={1}  spacing={2}>

                {/* First Row */}
                <Grid item xs={12}>
                    <Grid container direction="row" columnSpacing={1}>
                        <Grid item xs={4}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t("organization_name")}
                            id="org_name"
                            value={orgName}
                            placeholder = "Descriptive name to be shown in the GUI"
                            onChange={(e) => handleItemChange("orgName", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !orgNameChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},                                    
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="email"
                            label={t("admin_email")}
                            id="admin_email"
                            placeholder = "Email address of administrator"
                            value={adminEmail}
                            onChange={(e) => handleItemChange("adminEmail", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !adminEmailChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t("login_domain")}
                            id="login_domain"
                            placeholder = "Web address for user login"                 
                            value={loginDomain}
                            onChange={(e) => handleItemChange("loginDomain", e.target.value)}
                            sx={{
                                input: { color: theme.palette.text.primary, 
                                         background: !loginDomainChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                label: { color: theme.palette.text.primary },
                            }}
                            />
                        </Grid>
                    </Grid>

                </Grid> {/* Row Container */}


                {/* Second Row */}
                <Grid item xs={12}>

                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item >
                            <FormControl fullWidth>
                                <InputLabel
                                    sx={{ color: theme.palette.text.primary }}
                                    id="sentioInputLabel"
                                    shrink={sentioType !== ""}
                                >
                                    {t("sentioweb_variant")}
                                </InputLabel>
                                
                                <Box sx={{ backgroundColor: !sentioTypeChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light }}>
                                    <Select
                                    labelId="sentioInputLabel"
                                    value={sentioType}
                                    label={t("sentio_type")}
                                    disabled={false}
                                    onChange={(e) => handleItemChange("sentioType", e.target.value)}
                                    displayEmpty
                                    >
                                    {Object.entries(SENTIO_TYPES).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                        {value}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </Box>
                            </FormControl>


                        </Grid>


                        <Grid item >
                            <FormControl fullWidth>

                                <InputLabel
                                    sx={{ color: theme.palette.text.primary }}
                                    id="aiInputLabel"
                                    shrink={aiExecution !== ''}
                                    >
                                    {t("ai_execution")}
                                </InputLabel>
                                <Box sx={{ backgroundColor: !aiExecutionChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light }}>

                                    <Select
                                        labelId="aiInputLabel"
                                        value={  aiExecution   }
                                        label={t("ai_execution")}
                                        disabled={false}
                                        onChange={(e) => handleItemChange ("aiExecution", e.target.value)}
                                        displayEmpty
                                        sx={{
                                            input: { color: theme.palette.text.primary, 
                                                    background: !adminEmailChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                            width: {md:100}
                                        }}
                                        >
                                        {Object.entries(SENTIO_AI_YES_NO).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {t(value)}
                                            </MenuItem>
                                            ))}

                                    </Select>
                                    </Box>
                            </FormControl>
                        </Grid>



                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={t("hotstore_duration")}
                                id="hotstore_duration"
                                value={hotstoreDuration}
                                onChange={(e) => handleItemChange ("hotStoreDuration", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !hotstoreDurationChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: { md:120 }
                                }}
                                inputProps={{
                                    min: MIN_HOTSTORE_DURATION,
                                    max: MAX_HOTSTORE_DURATION,
                                  }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("data_resolution")}
                                id="data_resolution"
                                type="number"
                                onChange={(e) => handleItemChange ("dataResolution", e.target.value)}
                                value={dataResolution}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !dataResolutionChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                               label: { color: theme.palette.text.primary },
                                    width: { md:120 }
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("proxy_device_server")}
                                id="proxy_device_server"
                                value={proxyServer}
                                placeholder = "Proxy Device Server Address"
                                onChange={(e) => handleItemChange ("proxyServer", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !proxyServerChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:300}
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("port")}
                                id="proxyserver_port"
                                value={-1}
                                type="number"
                                disabled
                                onChange={(e) => handleItemChange ("proxyServerPort", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, background: theme.palette.background.default },
                                    label: { color: theme.palette.text.primary },
                                    width: {md:100}

                                }}
                                inputProps={{
                                    min: 0,
                                  }}

                            />
                        </Grid>
                    </Grid> 
                </Grid> {/* Row Container */}

                {/* Third Row */}                
                <Grid item xs={12}>
                    <Grid container direction="row" columnSpacing={1}>
                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("backingstore_host")}
                                id="backingstore_host"
                                value={backingStoreHost}
                                placeholder = "IP address of system database"
                                onChange={(e) => handleItemChange ("backingStoreHost", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !backingStoreHostChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:300}
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("port")}
                                id="backingstore_port"
                                value={backingStorePort}
                                type="number"
                                onChange={(e) => handleItemChange ("backingStorePort", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                        background: !backingStorePortChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:100}

                                }}
                                inputProps={{
                                    min: 0,
                                  }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("hotstore_host")}
                                id="hotstore_host"
                                placeholder = "IP Address of hotstorage database"
                                value={hotStoreHost}
                                onChange={(e) => handleItemChange ("hotStoreHost", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !hotStoreHostChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:300}
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("port")}
                                id="hotstore_port"
                                value={hotStorePort}
                                type="number"
                                onChange={(e) => handleItemChange ("hotStorePort", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !hotStorePortChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:100}
                                }}
                                inputProps={{
                                    min: 0,
                                  }}
                            />
                        </Grid>
                        <Grid item>
                            <IngestionServerDropdown 
                                ingestionServers={ingestionServers} 
                                defaultSelectedServer={currentSelectedServer}
                                //onSelect={handleSelection} 
                                onSelect = {(e) => handleItemChange ("IngestionSystem", e)}
                                serverChanged = {selectedServerChanged}
                            />
                        </Grid>
                    </Grid> 
                    </Grid> {/* Row Container */}


                {/* Fourth Row */}
                <Grid item xs={12}>

                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("ingestion_topic")}
                                id="ingestion_topic"
                                placeholder = "Kafka topic ID"
                                value={ingestionTopic}
                                onChange={(e) => handleItemChange ("ingestionTopic", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !ingestionTopicChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:300}
                                }}
                            />
                        </Grid>

                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("consumer_group")}
                                id="consumer_group"
                                value={consumerGroup}
                                onChange={(e) => handleItemChange ("consumerGroup", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !consumerGroupChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                    width: {md:200}

                                }}
                            />
                        </Grid>

                    </Grid> 
                </Grid> {/* Fifth Row Container */}

                {/* Fifth Row - only if MANUAL selection*/}
                {currentSelectedServer === "manual" && <Grid item xs={12}>

                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item xs={12}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label={t("ingestion_server")}
                                id="ingestion_server"
                                value={ingestionServer}
                                placeholder = "Kafka Server Address"
                                onChange={(e) => handleItemChange ("ingestionServer", e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, 
                                             background: !ingestionServerChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                    label: { color: theme.palette.text.primary },
                                }}
                            />
                        </Grid>

                    </Grid> 
                </Grid> }
                {/* Sixth Row Container */}

                {/* Sixth Row */}
                {currentSelectedServer === "manual" &&  <Grid item xs={12}>

                    <Grid container direction="row" columnSpacing={1}>

                        <Grid item xs={12}>
                            <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    multiline
                                    label={t("ingestion_key")}
                                    id="ingestion_key"
                                    value={ingestionKey}
                                    placeholder = "Kafka Secret Key"
                                    onChange={(e) => handleItemChange ("ingestionKey", e.target.value)}
                                    sx = {{  
                                        '& input, & textarea': { color: theme.palette.text.primary, 
                                                 background: !ingestionKeyChanged || !targetOrg ? theme.palette.background.default : theme.palette.warning.light},
                                        label: { color: theme.palette.text.primary },
                                    }}
                                />
                        </Grid>

                    </Grid> 
                </Grid>}
                {/* Seventh Row Container */}

            
            </Grid> {/* Outer container */}

                
            


            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={handleClose}> { t("close") }</Button>
                    {showSaveButton && (
								<Button onClick={handleSaveChanges} variant="outlined"
                                        sx={{background: theme.palette.warning.light}}
                                >
									{t("save_changes")}
								</Button>
							)}

                    <Button variant="outlined" onClick={deleteVICU} >  { t("delete_organization") }</Button>
                </Grid>
            </DialogActions>

        </Dialog>

    </>
    )
}

interface ItemProps {
    title: any;
    content: any;
}

export const CardItem = ({ title, content} : ItemProps) => {
    return <Grid container> 
                <Grid item xs={6}> <Typography  >{ title } </Typography> </Grid>
                <Grid item xs={6}> <Typography  >{ content}</Typography> </Grid>
            </Grid>
}
