import { ReactNode, useState } from "react";
import {
    Grid,
    Button,
    Typography,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NEWS } from "../../interfaces/NEWS";
import { Device } from "../../interfaces/Device";
import { GetColorNEWS } from "../../utils/functions/GetColorNEWS";
import { CustomRow } from "../CustomRow";
import { PatientInfo } from "../../pages/Home/Live/LiveUnit";
import SettingsIcon from "@mui/icons-material/Settings";
import { ConditionalLink } from "../../components/ConditionalLink";
import SensorsIcon from "@mui/icons-material/Sensors";
import SensorsOffIcon from "@mui/icons-material/Sensors";

const ENABLE_NEWS = false;

interface Props {
    device: Device;
    news?: NEWS | null;
    connectStatus: string;
    patientInfo: PatientInfo | null;
    selectParams?: ReactNode;
    useLink?: boolean;
}

export const TopPartMonitorNEWS = ({
    device,
    news,
    patientInfo,
    connectStatus,
    selectParams,
    useLink = true,
}: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleClickOpenSettings = () => {
        setOpen(true);
    };

    const handleCloseSettings = (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }
    };

    const monitorLink: string = "/wavemonitor/" + device.device_id + "/";

    //console.log ("Itemwidth:" , itemWidth)
    return (
        <Grid container direction="row" item xs={12}>
            <Dialog open={open} onClose={handleCloseSettings}>
                <DialogTitle>{t("select_parameters")}</DialogTitle>
                <DialogContent>{selectParams}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSettings}>{t("close")}</Button>
                </DialogActions>
            </Dialog>
            {/* container for header row */}
            <Grid
                container
                sx={{
                    bgcolor: "#004BA1",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    padding: "3px",
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid container item xs={8} direction="row">
                    {connectStatus && (
                        <>
                            {connectStatus === "connected" ? (
                                <SensorsIcon
                                    style={{ fontSize: 25 }}
                                    className="connectionOK"
                                />
                            ) : (
                                <SensorsOffIcon
                                    style={{ fontSize: 25 }}
                                    className="connectionFail"
                                />
                            )}
                        </>
                    )}
                    <ConditionalLink show={useLink} to={monitorLink}>
                        <Typography color={theme.palette.common.white} mx={1}>
                            {device.nickname ? device.nickname : device.device_id}
                        </Typography>
                    </ConditionalLink>
                </Grid>

                <Grid container item xs={1} justifyContent="center">
                    {selectParams && (
                        <SettingsIcon
                            style={{
                                color: "white",
                                fontSize: 20,
                                cursor: "pointer",
                            }}
                            onClick={handleClickOpenSettings}
                        />
                    )}
                </Grid>
            </Grid>{" "}
            {/* container for header row */}
            {/* container for NEWS row */}
            <Grid
                sx={{ bgcolor: "common.black" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <CustomRow alignItems="center">
                    <Typography color={theme.palette.common.white} px={1}>
                        {patientInfo?.first_name} {patientInfo?.last_name}
                    </Typography>

                    { ENABLE_NEWS && <Grid>
                        <CustomRow alignItems="center">
                            <Typography color={theme.palette.common.white}>
                                NEWS:
                            </Typography>
                            <Typography
                                sx={{
                                    marginLeft: "5px",
                                    padding: "3px",
                                    backgroundColor: GetColorNEWS(news?.score),
                                }}
                                color={
                                    GetColorNEWS(news?.score) !== "initial" &&
                                    news?.score
                                        ? theme.palette.common.black
                                        : theme.palette.common.white
                                }
                            >
                                {connectStatus === "connected" && news?.score
                                    ? news?.score + " - " + news?.scoretext
                                    : "--"}
                            </Typography>
                        </CustomRow>
                    </Grid>
}
                </CustomRow>
            </Grid>{" "}
            {/* container for NEWS row */}
        </Grid>
    );
};
