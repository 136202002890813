import { Link,
 } from "@mui/material";


    interface LinkProps {
        children: React.ReactNode;
        to:      string;
        show:    boolean;
    }
    export const ConditionalLink = ({ children, to, show } : LinkProps) => {

        if (show && to) {
            return (
                <>
                <Link target="_blank" href={to}> 
                    <>{children}</>
                </Link>
                
                </>
            )
        } else {
            return (
                <>{children}</>
                );
        }
    }
