import axios from "axios";

axios.interceptors.request.use((config) => {
    if (config.method === "delete" && config.data) {
        config.headers["Content-Type"] = "application/json";
        //config.data = JSON.stringify(config.data);
    }
    return config;
});

export default axios;
