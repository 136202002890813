import React, { useState } from 'react';
import { MenuItem, Box, FormControl, Select, InputLabel, SelectChangeEvent, useTheme } from '@mui/material';
import { IngestionServer } from '../../interfaces/IngestionServer';
import { useTranslation } from "react-i18next";

interface IngestionServerDropdownProps {
    ingestionServers: IngestionServer[];
    defaultSelectedServer?: string;
    onSelect?: (selectedServer: string) => void;
    serverChanged : boolean;
  }

  
  const IngestionServerDropdown: React.FC<IngestionServerDropdownProps> = ({ ingestionServers, onSelect, defaultSelectedServer, serverChanged }) => {
    
    console.log ("IngestionServerDropdown:", serverChanged, ingestionServers, defaultSelectedServer)
    const [selectedServer, setSelectedServer] = useState<string>(defaultSelectedServer || '');
    const theme = useTheme();
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<string>) => {
        console.log ("New selected server:", event.target.value)
        const value = event.target.value as string; // Ensure the value is treated as a string
        setSelectedServer(value);
        onSelect?.(value);
      };
  
    return (
      <FormControl fullWidth>
        <InputLabel 
            sx={{ color: theme.palette.text.primary }} 
            id="serverSelectLabel" 
            shrink={true}
            >
                {t ("ingestion_system")}
        </InputLabel>

        <Box sx={{ backgroundColor: !serverChanged  ? theme.palette.background.default : theme.palette.warning.light }}>
            <Select
            labelId="serverSelectLabel"
            value={selectedServer}
            label = "Ingestion Server"
            onChange={handleChange} 
            displayEmpty
            sx={{
                width: {md:400}
            }}
            >
            {ingestionServers.map((server) => (
                <MenuItem key={server.server_id} value={server.server_id}>
                {server.name}
                </MenuItem>
            ))}
            </Select>
        </Box>
      </FormControl>
    );
  };

export default IngestionServerDropdown;

            // <MenuItem value="manual">Manual Configuration</MenuItem>

