import {
	Avatar,
	Divider,
	Grid,
	Typography,
	useTheme,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";

import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
//import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import UserService from "../../services/UserService";
//import { User } from "../../interfaces/user/User";
//import { GetFileFromServer } from "../../utils/functions/GetFileFromServer";
//import QRCode from "react-qr-code";
//import { DialogConfirm } from "../../components/DialogConfirm";
import { AlertMessage } from "../../components/AlertMessage";
import { useAlertMessage } from "../../hooks/useAlertMessage";
import { useCookies } from "react-cookie";

export const Profile = () => {
	const theme = useTheme();
	const userCtx = useContext(UserContext);
	const { t } = useTranslation();
	const [oldPassValue, setOldPassValue] = useState("");
	const [passValue, setPassValue] = useState("");
	const [pass2Value, setPass2Value] = useState("");
	const [passMatch, setPassMatch] = useState(true);
	const [passwordError, setPasswordError] = useState(true);
	const [showPassError, setShowPassError] = useState(false);
	const [showPassOK, setShowPassOK] = useState(false);
	const [cookies, ,] = useCookies(["access_token"]);

	var access_token = cookies.access_token;

	const checkPasswords = (p1: string, p2: string) => {
		setPassMatch(p1 === p2);
	};

	useEffect(() => {
		checkPasswords(passValue, pass2Value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passValue, pass2Value]);

	const {
		isOpen: isAlertOpen,
		//        setIsOpen: setIsAlertOpen,
		message,
		//        setMessage,
		type,
		//        setType,
	} = useAlertMessage();

	const [openPassdialog, setOpenPassDialog] = useState(false);

	const handleClickOpen = () => {
		setOpenPassDialog(true);
		setPasswordError(true);
	};

	const handleCloseSave = () => {
		console.log("Close dialog handler");
		setShowPassError(false);

		UserService.setPassword(access_token, oldPassValue, passValue)
			.then((res: any) => {
				console.log("Result:", res);
				if (res.code === 200) {
					console.log("Success! " + res.message);
					setShowPassOK(true);
					console.log("showPassOK:", showPassOK);
				}
			})
			.catch((error: any) => {
				console.log("Error updating the password");
				setShowPassError(true);
			})
			.finally(() => {
				console.log("But finally done");
			});
	};

	const handleCloseCancel = () => {
		setOpenPassDialog(false);
	};

	const handlePassErrorClose = () => {
		setShowPassError(false);
	};
	const handlePassOKClose = () => {
		setShowPassOK(false);
		setOpenPassDialog(false);
	};

	const [autoLogoutMinutes, setAutoLogoutMinutes] = useState<string>(
		userCtx?.user?.autoLogout ?? "15"
	);

	const handleChangeAutoLogoutMinutes = (event: SelectChangeEvent) => {
		setAutoLogoutMinutes(event.target.value as string);
		localStorage.setItem(
			"user",
			JSON.stringify({
				...userCtx?.user,
				autoLogout: event.target.value as string,
			})
		);
		userCtx?.setUser({
			...userCtx?.user,
			autoLogout: event.target.value as string,
		});
	};

	return (
		<div>
			<Dialog open={showPassOK}>
				<DialogTitle> {t("password_change_successful")} </DialogTitle>
				<DialogContent></DialogContent>
				<Button onClick={handlePassOKClose}>OK</Button>
			</Dialog>

			<Dialog open={showPassError}>
				<DialogTitle>{t("password_change_error")}</DialogTitle>
				<DialogContent></DialogContent>
				<Button onClick={handlePassErrorClose}>OK</Button>
			</Dialog>

			<Dialog open={openPassdialog}>
				<DialogTitle>{t("change_password")} </DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="old_password"
						onChange={(e) => {
							setOldPassValue(e.target.value);
						}}
						label={t("old_password")}
						type="password"
						fullWidth
						variant="outlined"
						sx={{
							input: {
								color: theme.palette.text.primary,
								background: theme.palette.background.default,
							},
							label: {
								color: theme.palette.text.primary,
							},
						}}
					/>
					<TextField
						id="new_password"
						label={t("new_password")}
						type="password"
						fullWidth
						variant="outlined"
						color="primary"
						onChange={(e) => {
							setPassValue(e.target.value);
						}}
						sx={{
							input: {
								color: theme.palette.text.primary,
								background: theme.palette.background.default,
							},
							label: {
								color: theme.palette.text.primary,
							},
						}}
					/>
					<TextField
						margin="dense"
						id="new_password2"
						onChange={(e) => {
							setPass2Value(e.target.value);
						}}
						label={t("repeat_password")}
						type="password"
						fullWidth
						variant="outlined"
						sx={{
							input: {
								color: theme.palette.text.primary,
								background: theme.palette.background.default,
							},
							label: {
								color: theme.palette.text.primary,
							},
						}}
					/>
					{!passMatch && (
						<Typography color={theme.palette.error.main}>
							{t("passwords_dont_match")}
						</Typography>
					)}

					{!passwordError && (
						<Typography color={theme.palette.error.main}>
							{t("error_updating_password")}
						</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseCancel}>{t("cancel")}</Button>
					<Button onClick={handleCloseSave}>{t("save")}</Button>
				</DialogActions>
			</Dialog>

			<Grid container justifyContent="center" alignItems="center">
				<Card>
					<Grid>
						<CardContent>
							<Typography
								gutterBottom
								variant="h5"
								component="div"
							>
								{userCtx?.user &&
									userCtx.user.first_name +
										" " +
										userCtx.user.last_name}
							</Typography>
							<List>
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<PersonIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={userCtx?.user?.username}
										secondary={
											<Typography
												color={
													theme.palette.text.disabled
												}
											>
												{t("username")}
											</Typography>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<LocalHospitalIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={userCtx?.user?.organization?.toUpperCase()}
										secondary={
											<Typography
												color={
													theme.palette.text.disabled
												}
											>
												{t("organization")}
											</Typography>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<EmailIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={userCtx?.user?.email}
										secondary={
											<Typography
												color={
													theme.palette.text.disabled
												}
											>
												E-mail
											</Typography>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
								<ListItem>
									<ListItemAvatar>
										<Avatar>
											<PhoneIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={userCtx?.user?.phone}
										secondary={
											<Typography
												color={
													theme.palette.text.disabled
												}
											>
												{t("phone")}
											</Typography>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
							</List>
						</CardContent>
						<CardActions>
							<Grid
								container
								direction="row"
								justifyContent="center"
							>
								<FormControl fullWidth>
									<InputLabel sx={{ color: "text.primary" }}>
										Auto-logout
									</InputLabel>
									<Select
										value={autoLogoutMinutes.toString()}
										label="Auto-logout"
										onChange={handleChangeAutoLogoutMinutes}
									>
										<MenuItem value={5}>5 minutes</MenuItem>
										<MenuItem value={15}>
											15 minutes
										</MenuItem>
										<MenuItem value={60}>
											60 minutes
										</MenuItem>
									</Select>
								</FormControl>

								<Button onClick={handleClickOpen}>
									{t("change_password")}
								</Button>
							</Grid>
						</CardActions>
					</Grid>
				</Card>

				<AlertMessage
					type={type}
					message={message}
					open={isAlertOpen}
				/>
			</Grid>
		</div>
	);
};
