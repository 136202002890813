import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Link,  Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import {  MenuItem, FormControl, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ContentCopy } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';
import { useCookies } from "react-cookie";
import DeviceService from "../services/DeviceService";
import * as CONSTANTS from "../utils/constants/constants";
import { red } from '@mui/material/colors';


interface VideoLinkCardProps {
    device_id?: string;
    initialVideoLink: string;
    initialEnabled: boolean;
    initialExpirationTime: Date;
    setDeviceVideoLink: (link:string, expiry:string) => void;
  }

  

const VideoLinkCard: React.FC<VideoLinkCardProps> = ({
  device_id,
  initialVideoLink,
  initialEnabled,
  initialExpirationTime,
  setDeviceVideoLink
}) => {
    const [videoLink, setVideoLink] = useState<string | null>(initialVideoLink);
    const [isActive, setIsActive] = useState<boolean>(initialEnabled);
    const [expirationDate, setExpirationDate] = useState<Date | null>(initialExpirationTime);
    const [cookies, ,] = useCookies(["access_token"]);

    const [expirationDateOption, setExpirationDateOption] = useState<string>('0');
    const [expirationHour, setExpirationHour] = useState<string>('12');
    const [expirationPeriod, setExpirationPeriod] = useState<string>('AM');
  
    const handleExpirationDateChange = (event: SelectChangeEvent<string>) => {
      setExpirationDateOption(event.target.value as string);
    };
    
    const handleExpirationHourChange = (event: SelectChangeEvent<string>) => {
      setExpirationHour(event.target.value as string);
    };
    
    const handleExpirationPeriodChange = (event: SelectChangeEvent<string>) => {
      setExpirationPeriod(event.target.value as string);
    };
    



    const deactivateLink = () => {
      setIsActive(false);
      setVideoLink(null);
      setExpirationDate(null);
      generateNewVideoLink(null); // Set the new video link  
    };

  

  const [isNestedDialogOpen, setIsNestedDialogOpen] = useState(false);

  const handleGenerateLinkClick = () => {
    setIsNestedDialogOpen(true);
  };

  const handleNestedDialogClose = () => {
    setIsNestedDialogOpen(false);
  };

  const calculateNewExpirationDate = () => {
    const currentDate = new Date();
    const daysToAdd = parseInt(expirationDateOption, 10);
    var hourToSet = expirationPeriod === 'PM' ? parseInt(expirationHour, 10) + 12 : parseInt(expirationHour, 10);
    
    // Adjust for 12AM and 12PM
    if (expirationHour === '12' && expirationPeriod === 'AM') {
      hourToSet = 0;
    } else if (expirationHour === '12' && expirationPeriod === 'PM') {
      hourToSet = 12;
    }
  
    currentDate.setDate(currentDate.getDate() + daysToAdd);
    currentDate.setHours(hourToSet, 0, 0, 0); // Set minutes, seconds, and milliseconds to 0
    
    return currentDate;
  };

  const generateNewVideoLink = (expirationDate: Date | null) => {
      const data = {
        expirationDate: expirationDate,
        device_id: device_id
      }

      console.log ("generateNewVideoLink:", data)
      DeviceService.generateVideoLink(data, cookies.access_token).then((response: any) => {
        console.log ("Response:", response)
        if (response && (response.status === 200 )) {

          if (response.data.link === "") {
            setVideoLink (null);
            setExpirationDate (null);
          } else {
            // Update the screen
            setVideoLink(CONSTANTS.HOST + response.data.link);

            // Update the local variables
            setDeviceVideoLink (CONSTANTS.HOST + response.data.link, response.data.expiry)
            setIsActive(true);
            setExpirationDate(calculateNewExpirationDate());
          }
        } else {
          console.error('Failed to generate video link:', response);
          // Handle the error as you see fit, e.g., show a notification to the user
        }
        
      }).catch((err: any) => {
        console.error('Failed to generate video link:', err);
        return err.data;
    })
  }
  

  const handleNestedDialogOk = () => {
    const newExpirationDate = calculateNewExpirationDate(); // Implement this function to calculate the new expiration date based on the selected options
    setExpirationDate(newExpirationDate);
    generateNewVideoLink(newExpirationDate); // Set the new video link
    setIsActive(true); // Ensure the video link is marked as active
    setIsNestedDialogOpen(false); // Close the nested dialog
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (videoLink) { // This checks that videoLink is not null or an empty string
      copy(videoLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 500); // Reset the state after 2 seconds
    }
  };
  
  const linkExpired = expirationDate && expirationDate < new Date ();
  
  return (
    <Card>
      <CardContent>


        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Typography variant="h6">Pet Parent Video Link</Typography>
          <div style={{ textAlign: 'right' }}>
            {isActive && videoLink && expirationDate ? (
              <div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                  <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
                    <ContentCopy 
                      style={{ cursor: 'pointer', marginRight: 4, color: copied ? 'green' : 'inherit' }}
                      onClick={handleCopy}
                    />
                  </Tooltip>
                  <Link href={videoLink} target="_blank">
                    Pet Video Link
                  </Link>
                </div>
                {linkExpired ? (
                  <Typography variant="body2" style={{ color: red[500] }}>
                      LINK EXPIRED: {expirationDate.toLocaleString()}
                  </Typography>
                  ) : (
                      <Typography variant="body2">
                          Expiration Date: {expirationDate.toLocaleString()}
                      </Typography>
                  )}
              </div>
            ) : (
              <Typography style={{ marginBottom: 16 }}>No active video link</Typography>
            )}
          </div>
        </div>



        <div style={{ marginTop: 16, display: 'flex', gap: 8  }}>
          <Button variant="contained" color="primary" onClick={handleGenerateLinkClick}>
            Generate Video Link
          </Button>
          { videoLink && <Button variant="contained" color="secondary" onClick={deactivateLink}>
            Deactivate Video Link
          </Button>
}

          <Dialog open={isNestedDialogOpen} onClose={handleNestedDialogClose}>
            <DialogTitle>Set Expiration Time</DialogTitle>
            <DialogContent>
              {/* Add your dropdown selectors and expiration time label here */}
              <div style={{ marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel>Expiration Date</InputLabel>
                  <Select
                    value={expirationDateOption}
                    onChange={handleExpirationDateChange}
                  >
                    <MenuItem value="0">Today</MenuItem>
                    <MenuItem value="1">Tomorrow</MenuItem>
                    <MenuItem value="2">In 2 Days</MenuItem>
                    <MenuItem value="3">In 3 Days</MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel>Expiration Hour</InputLabel>
                  <Select
                    value={expirationHour}
                    onChange={handleExpirationHourChange}
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>AM/PM</InputLabel>
                  <Select
                    value={expirationPeriod}
                    onChange={handleExpirationPeriodChange}
                  >
                    <MenuItem value="AM">AM</MenuItem>
                    <MenuItem value="PM">PM</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNestedDialogClose}>Cancel</Button>
              <Button onClick={handleNestedDialogOk}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
        
       
      </CardContent>
    </Card>
  );
};

export default VideoLinkCard;
