import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    Typography,
} from "@mui/material";

import { Loading } from "../../components/Loading";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isBrowser } from "react-device-detect";
import { IngestionServer } from "../../interfaces/IngestionServer";
import { IngestionServerDialog } from "./IngestionServerDialog";

interface Props {
    serverList: IngestionServer[];
    dialogMode: string;
}

export const IngestionServerTable = ({ serverList, dialogMode }: Props) => {
    //console.log ("IngestionServerTable renders: ", dialogMode)
    const [server, setServer] = useState<IngestionServer | null>(null);
    const [showDialog, setShowDialog] = useState(dialogMode === "add");
    
    const { t } = useTranslation();
    const theme = useTheme();
    const fontVariant = isBrowser ? "body2" : "caption";

    if (!serverList) {
        return <Loading />;
    }

    //console.log("Ingestion Server list:", dialogMode, serverList);
    return (
        <TableContainer component={Paper}>
            {server && (
                <IngestionServerDialog
                    openState={showDialog}
                    setOpenState={setShowDialog}
                    targetServer={server}
                    dialogMode = { "edit" }
                />
            )}

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow
                        sx={{
                            border: 0,
                            bgcolor: theme.palette.primary.main,
                            "& th": {
                                color: theme.palette.text.secondary,
                            },
                        }}
                    >

                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("server_name")}
                        </TableCell>

                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("server_id")}
                        </TableCell>
                        
                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("server_type")}
                        </TableCell>

                        <TableCell
                            sx={{
                                padding: "16px 6px",
                            }}
                        >
                            {t("enabled")}
                        </TableCell>

                 

                    </TableRow>
                </TableHead>
                <TableBody>
                    {serverList?.map((server: IngestionServer, i: number) => (
                        <TableRow
                            onClick={() => {
                                setServer(server);
                                //vicuRef.current = v;
                                setShowDialog(true);
                            }}
                            key={i}
                            hover
                            sx={{
                                "&:nth-of-type(odd)": {
                                    background: theme.palette.info.dark,
                                },
                                "&:nth-of-type(even)": {
                                    background: theme.palette.info.light,
                                },
                                cursor: "pointer",
                            }}
                        >

                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {server.name}
                                </Typography>
                            </TableCell>

                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {server.server_id}
                                </Typography>
                            </TableCell>

                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {server.serverType}
                                </Typography>
                            </TableCell>

                            <TableCell
                                sx={{
                                    padding: "16px 6px",
                                }}
                            >
                                <Typography variant={fontVariant}>
                                    {server.enabled ? t("yes") : t ("no")}
                                </Typography>
                            </TableCell>



                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
