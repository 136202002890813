import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import { useState } from "react";
import { User } from "../../interfaces/user/User";
import { IngestionServerDialog} from "./IngestionServerDialog"
import { IngestionServerTable } from "./IngestionServerTable";
import IngestionServerService from "../../services/IngestionServerService";

export const SysAdmin = () => {
    //console.log ("SysAdmin renders...")
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState ("edit");
	const [user] = useState<User | null>(
		// @ts-ignore
		JSON.parse(localStorage.getItem("user")) || null
	);

    // Fetch all Ingestion Servers
    const {
        data: ingestionList,
        loading: serversLoading,
        error: serverError,
    } = useFetch({
        url: IngestionServerService.getAllIngestionServers(),
        access_token: cookies.access_token,
    });

    if (serversLoading) {
        return <Loading />;
    }
    if (serverError) {
        return <ServerProblem />;
    }

    //if (!ingestionList || ingestionList.length === 0) {
    //    return <>No Ingestion Servers have been defined</>;
    //}

    const addDialog = () => {
        setDialogMode ("add");
        setShowDialog(true);
    };

    return (
        <div>
                <IngestionServerDialog
                    openState={showDialog}
                    setOpenState={setShowDialog}
                    targetServer={undefined}
                    dialogMode = { "add" }
                />

            <Grid item>
                <IngestionServerTable serverList={ingestionList} dialogMode = {dialogMode} />
            </Grid>

            { user?.is_superadmin && 
                <Grid item sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={addDialog}>
                        {t("add_ingestionserver")}
                    </Button>
                </Grid>
            }
        </div>
    );
};
