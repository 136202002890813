import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SyntheticEvent, Fragment, useEffect } from "react";
import { useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Fragment>{children}</Fragment>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface Props {
    tabNames: string[];
    tabComponents?: React.ReactNode[];
    value: number; 
    onTabChange: (newValue: number) => void;
}

export default function CustomTabs({ tabNames, tabComponents, value, onTabChange }: Props) {

    const handleChange = (_: SyntheticEvent, newValue: number) => {
        onTabChange(newValue); // <- Here
    };
    const location = useLocation();

    const theme = useTheme();

    useEffect(() => {
        // @ts-ignore
        if (location.state && location.state.startingTab) {
            // @ts-ignore
            setValue(location.state.startingTab);
        }
    }, [location.state]);

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile}
                    allowScrollButtonsMobile
                >
                    {tabNames.map((tab, i) => {
                        const isSysPoolTab = tab.toLowerCase() === 'manage groups';
                        return (
                            <Tab
                                key={tab}
                                label={
                                    <span
                                        style={{
                                            color: isSysPoolTab ? 'orange' : theme.palette.text.primary,
                                            fontSize: isBrowser ? "inherit" : "12px",
                                        }}
                                    >
                                        {tab}
                                    </span>
                                }
                                {...a11yProps(i)}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {tabComponents?.map((tab, i) => {
                return (
                    <TabPanel key={i} value={value} index={i}>
                        {tab}
                    </TabPanel>
                );
            })}
        </Box>
    );
}
