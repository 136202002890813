import { Dispatch, SetStateAction, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			width: 250,
		},
	},
};

interface Props {
	deviceId: string;
	selectableParameters: string[];
	selectedParameters: string[];
	setSelectedParameters: Dispatch<SetStateAction<string[]>>;
	maxParameters: number;
	type: string;
	width?: string;
	margin?: number;
  }
  
  // Component function definition
  export const SelectParameters: React.FC<Props> = ({
	deviceId,
	selectableParameters,
	selectedParameters,
	setSelectedParameters,
	maxParameters,
	type,
	width,
	margin
  }) => {
	//console.log ("SelectParameters", selectableParameters, selectedParameters, selectedParameters.length, maxParameters)
	// Selection change event

	const handleChange = (event: SelectChangeEvent<string[]>) => {
		const newSelectedParameters = event.target.value as string[];
		//console.log("handleChange newSelectedParameters:", newSelectedParameters);
		setSelectedParameters(newSelectedParameters);
		
		const dest = `${type}-${deviceId}`;
		localStorage.setItem(dest, JSON.stringify(newSelectedParameters));
	  };
	  
	  useEffect(() => {
		//console.log("Selected Parameters updated via useEffect:", selectedParameters);
	  }, [selectedParameters]);
	  
	  

	const { t } = useTranslation();  
	return (
		<FormControl sx={{ mt: margin ?? 2, width: width ?? 250 }}>
			<InputLabel
				sx={{ color: "text.primary" }}
				id="demo-multiple-checkbox-label"
			>
				{t("parameters")}
			</InputLabel>
			<Select
				labelId="demo-multiple-checkbox-label"
				id="demo-multiple-checkbox"
				multiple
				value={selectedParameters}
				onChange={handleChange}
				input={<OutlinedInput label={t("parameters")} />}
				renderValue={(selected) =>
					selected
						.map((p) => {
							return t(p);
						})
						.join(", ")
				}
				MenuProps={MenuProps}
			>
				{selectableParameters.map((name) => (
					<MenuItem
						key={name}
						value={name}
						disabled={selectedParameters.length === maxParameters && !selectedParameters.includes(name)}
					>
						<Checkbox
						checked={selectedParameters.includes(name)}
						/>
						<ListItemText primary={t(name)} />
					</MenuItem>
					))}

			</Select>
		</FormControl>
	);
}
