import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export type ProtectedRouteProps = {
    component: React.FC;
    authenticationPath?: string;
} & RouteProps;

export default function ProtectedRoute({
    component,
    authenticationPath,
    ...routeProps
}: ProtectedRouteProps) {
    const userCtx = useContext(UserContext);
    const [cookies, , removeCookies] = useCookies([
        "access_token",
        "refresh_token",
    ]);

    useEffect(() => {
        if (!cookies.access_token) {
            userCtx?.setUser(null);
            localStorage.removeItem("user");
            removeCookies("access_token");
            removeCookies("refresh_token");
        }
    });

    if (cookies.access_token) {
        return (
            <UserContext.Provider value={userCtx}>
                <Route component={component} {...routeProps} />
            </UserContext.Provider>
        );
    } else {
        return (
            <Redirect
                to={{
                    pathname: authenticationPath || "/login",
                }}
            />
        );
    }
}
