import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCookies } from "react-cookie";
import VICUService from "../../services/VICUService";
import { GranularitySelector } from "../../components/chart/GranularitySelector"
import {VICU_TYPES} from "../../interfaces/VICU"


import "./VicuManagement.css"
interface Props {
    openState       : boolean;
    setOpenState    : (show:boolean) => void;
}  

export const AddVicuDialog = ({openState, setOpenState} : Props) => {
     //console.log ("Alarm profiles alarmProp:", alarmProp)
 
    const [cookies, ,] = useCookies(["access_token"]);
    const [showSaveButton, setShowSaveButton] = useState (false);
    const [vicuname, setVicuname] = useState <string> ("")
    const [contact, setContact]   = useState <string> ("")
    const theme = useTheme();
    const { t } = useTranslation();
    const [parmGranularity, setParmGranularity] = useState (10)
    const [vicuType, setVicuType] = useState ("continuous")
    const [vicuDesc, setVicuDesc] = useState <string> (t("continuous_desc"))


    const handleClose = () => {
        setOpenState (false);
    }

    const handleVicunameChange = (val: string) => {
        setVicuname (val);
        if (val.trim().length > 0) {
            setShowSaveButton (true)
        } else {
            setShowSaveButton (false)
        }
    }

    const handleVicutypeChange = (event: SelectChangeEvent) => {
        console.log ("NEW VICU TYPE:", event.target.value)
        setVicuType(event.target.value);
        setVicuDesc (t (event.target.value + "_desc"))
    };

    const handleContactChange = (val: string) => {
        setContact (val);
    }

    const handleSaveChanges = () => {
        // Create the VICU in the back-end

        const data = {
            name      : vicuname,
            vicu_type : vicuType,
            contact   : contact,
            parm_granularity : parmGranularity
        }

        VICUService.addVICU(data, cookies.access_token)
            .then((res: any) => {
                console.log ("Success!")
                window.location.href = "/vicumgmt";
            }).catch((err: any) => {
                console.log(err);
            });

        // Then reload the data.
    }

      return (

        <Dialog  open = {openState} onClose={handleClose}  fullWidth maxWidth="sm">
            <DialogTitle> {t ("add_monitorgroup") } </DialogTitle>
            <DialogContent>

                <Grid container item >

                    {/* Monitor Group Name and Contact */}
                    <Grid container direction="row" pt={3} columnSpacing={1}>
                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("group_name")}
                                id="vicu_name"
                                value={vicuname}
                                onChange={(e) => handleVicunameChange (e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, background: theme.palette.background.default },
                                    label: { color: theme.palette.text.primary }
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label={t("contact")}
                                id="contact"
                                value={contact}
                                onChange={(e) => handleContactChange (e.target.value)}
                                sx = {{ 
                                    input: { color: theme.palette.text.primary, background: theme.palette.background.default },
                                    label: { color: theme.palette.text.primary }
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Monitor Group Type and Description */}
                    <Grid container direction="row" pt={3}>
                        <Grid item xs = {6}>
                            <FormControl >
                                <InputLabel
                                    sx={{ color: "text.primary" }}
                                    id="vicutype"
                                >
                                    {t("vicu_type")}
                                </InputLabel>

                                <Select
                                    value={  vicuType   }
                                    label={t("vicu_type")}
                                    onChange={handleVicutypeChange}
                                >
                                    { VICU_TYPES.map ((key) => (
                                        <MenuItem key={key} value={key}>
                                            {t(key)}
                                        </MenuItem>   
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography id="group_description"> {vicuDesc} </Typography>
                        </Grid>
                    </Grid>

                    {/* Monitor Group Data Resolution */}
                    { vicuType === "continuous" && <Grid item pt={3} >
                            <GranularitySelector sliderWidth={300} setParmInterval={setParmGranularity} title={t("set_default_parameter_interval")}/>
                    </Grid>
                    }

                </Grid> 

            

            
            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="space-between"  alignItems="center" >
                    <Button variant="outlined" onClick={handleClose} sx={{pr:2}}> { t("cancel") }</Button>
                    { showSaveButton && <Button variant="outlined" onClick={handleSaveChanges}> { t("save_group") }</Button> }
                </Grid>
            </DialogActions>
        </Dialog>


    )
}

interface ItemProps {
    title: any;
    content: any;
}

export const CardItem = ({ title, content} : ItemProps) => {
    return <Grid container> 
                <Grid item xs={6}> <Typography  >{ title } </Typography> </Grid>
                <Grid item xs={6}> <Typography  >{ content}</Typography> </Grid>
            </Grid>
}
