import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import VICUService from "../../services/VICUService";
import useFetch from "../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


interface UserAccessDialogProps {
  username: string;
  open: boolean;
  onClose: () => void;
  onOk: (updatedGroups: GroupType[]) => void
}

export type GroupType = {
    vicu_id: string;
    vicu_name: string;
    vicu_type: string;
    assigned?: boolean;  // Using the optional flag as it's going to be added later
};


const UserAccessDialog: React.FC<UserAccessDialogProps> = ({ open, onClose, onOk, username }) => {
  const [filteredItems, setFilteredItems] = useState<GroupType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [cookies, ,] = useCookies(["access_token"]);
  const displayedItems = filteredItems.filter(item => item.vicu_name.toLowerCase().includes(searchTerm.toLowerCase()));
  const { t } = useTranslation();
  const [combinedGroups, setCombinedGroups] = useState<GroupType[]>([]);

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleCheckboxChange = (vicu_id: string) => {
        const updatedGroups = filteredItems.map(group => {
            if (group.vicu_id === vicu_id) {
                return { ...group, assigned: !group.assigned };
            }
            return group;
        });
        setCombinedGroups(updatedGroups);
        setFilteredItems(updatedGroups)
     };
     

  // Retrieve Monitor Group membership for a given user
  const {
    data: monitorGroupList,
    //loading: monitorGroupLoading,
    //error: monitorGroupError,
} = useFetch({
    url: VICUService.getUserGroups(username),
    access_token: cookies.access_token,
});

  useEffect(() => {
    if (monitorGroupList === null) {
        return;
    }
    let unassignedGroups = monitorGroupList.unassignedGroups;
    let assignedGroups   = monitorGroupList.assignedGroups;
    assignedGroups   = assignedGroups.map((group: GroupType) => ({ ...group, assigned: true }));
    unassignedGroups = unassignedGroups.map((group: GroupType) => ({ ...group, assigned: false }));
    const combinedGroups = [...assignedGroups, ...unassignedGroups];
    combinedGroups.sort((a, b) => a.vicu_name.localeCompare(b.vicu_name));

    console.log ("Monitor group list:", combinedGroups)
    setFilteredItems (combinedGroups)

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [monitorGroupList]);

const handleOkClick = () => {
    onOk(combinedGroups); // Call the callback prop with combinedGroups
    onClose();
};


return (
    <Dialog open={true} onClose={onClose}>
        <DialogTitle>{t("Manage Group Access for ") + username}</DialogTitle>
        <DialogContent>
            <TextField  placeholder ={t("Type to filter ...")} variant="outlined" style={{ marginTop: '16px' }} fullWidth onChange={handleFilter} />
            <List>
                {displayedItems.map(item => (
                    <ListItem key={item.vicu_id}  onClick={() => handleCheckboxChange(item.vicu_id)}>
                        <Checkbox 
                            style={{ border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#fff', marginRight: "10px" }}
                            checked={item.assigned ?? false} 
                            onChange={(e) => e.stopPropagation()} // prevent double firing
                            edge="start"
                        />
                        <ListItemText primary={item.vicu_name} />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">{t("cancel")}</Button>
            <Button onClick={handleOkClick} color="primary">{t("ok")}</Button>
        </DialogActions>
    </Dialog>
);


};

export default UserAccessDialog;
