import * as CONSTANTS from "../utils/constants/constants";
import axios from "./axiosInstance"; // Replace with the path to your axiosInstance.js file


axios.interceptors.request.use((config: any) => {
    if (config.method === "delete" && config.data) {
        config.headers["Content-Type"] = "application/json";
        config.data = JSON.stringify(config.data);
    }
    return config;
});



class IngestionServerService {
	static getAllIngestionServers() {
		return CONSTANTS.HOST + "api/v1/sysadmin/servers/";
	}

    static getIngestionServer(server_id: string) {
        return CONSTANTS.HOST + "api/v1/sysadmin/server/" + server_id + "/";
    }

    static editIngestionServer(data:any, access_token: string) {
        return axios
            .patch(
                CONSTANTS.HOST + "api/v1/sysadmin/server/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static addIngestionServer(data:any, access_token: string) {
        return axios
            .post(
                CONSTANTS.HOST + "api/v1/sysadmin/server/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response) 
            .catch((err: any) => {
                console.log(err);
                return Promise.reject(err.response);
            });
    }
        
    static deleteIngestionServer(data: any, access_token: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/v1/sysadmin/server/", {
                headers: { authorization: "Bearer " + access_token },
                data: data
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
        

}

export default IngestionServerService;
