import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Device } from "../interfaces/Device";

interface DeviceSocketCtx {
    devices: Device[] | null;
    setDevices: Dispatch<SetStateAction<Device[] | null>>;
}

export const DeviceSocketContext = createContext<DeviceSocketCtx | null>(null);

export default function DeviceSocketProvider({ children }: any) {
    const [devices, setDevices] = useState<Device[] | null>(null);

    const deviceSocketContext = { devices, setDevices };

    return (
        <DeviceSocketContext.Provider value={deviceSocketContext}>
            {children}
        </DeviceSocketContext.Provider>
    );
}
