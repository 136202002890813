//import {
//    useTheme,
//} from "@mui/material";
//
//import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { DeviceTable } from "../../pages/Home/DeviceTable";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import { useState, useEffect } from 'react';
import { Button,  } from '@mui/material';
import { DeviceMgmtDialog } from "./DeviceMgmtDialog";

import { Device } from "../../interfaces/Device";


export const DeviceManagement = () => {
    //console.log ("==-- DeviceManagement renders")
    //const theme = useTheme();
    //const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    var token = cookies.access_token;// userCtx.user.token;
    //const [device, ] = useState<Device | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [deviceList, setDeviceList] = useState <Device[]>([]);


    // Function to handle dialog open
    const handleOpen = () => {
//        setOpen(true);
        setShowDialog (true);
    };

    function insertDeviceInSortedArray(devices: Device[], newDevice: Device): Device[] {
        const insertIndex = devices.findIndex(device => device.device_id > newDevice.device_id);
        if (insertIndex === -1) {
            devices.push(newDevice); // Add to the end if no larger id is found
        } else {
            devices.splice(insertIndex, 0, newDevice); // Insert at the correct index
        }
        return devices;
    }
    
    const addDevice = (device: Device) => {
        insertDeviceInSortedArray (deviceList, device)
    }
    const removeDevice = (deviceIdToRemove: string) => {
        const updatedList = deviceList.filter(device => device.device_id !== deviceIdToRemove);
        setDeviceList(updatedList);
    };
    const updateDevice = (updatedDevice: Device) => {
        const deviceIndex = deviceList.findIndex(device => device.device_id === updatedDevice.device_id);
        if (deviceIndex !== -1) {
            deviceList[deviceIndex] = { ...deviceList[deviceIndex], ...updatedDevice };
        } 
    }

    // Retrieve all devices for this organization the when the CMSDashboard renders.
    // Should only happen once, as this is the top-level component.
    const {
        data,
        loading: deviceLoading,
        error:   deviceError,
    } = useFetch({
        url: DeviceService.getDevices (),
        access_token: token
    });
    
    // One-time on component load: retrieve alarm profiles from back-end
    const {
        data:    alarmProfiles,
        loading: almLoading,
        error:   almError        
    } = useFetch({
        url: DeviceService.getTypes (),
        access_token: token
    });

    // Update deviceList state when data is fetched
    useEffect(() => {
        if (data) {
            setDeviceList(data);
        }
    }, [data]);

    if (almLoading || deviceLoading || !alarmProfiles) {
        return <Loading/>
    }
    if (almError || deviceError) {
        return <ServerProblem/>
    }

    //console.log ("Retrieve for devicetable:", deviceList)
    //console.log ("alarmProfiles:", alarmProfiles)
    return (
        <div>
            <DeviceTable    devicelist = {deviceList} alarmProp = {alarmProfiles} removeDevice = {removeDevice} updateDevice ={updateDevice}/>
            {/* Add Device button */}
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Add Device
            </Button>

            <DeviceMgmtDialog
                action={"add"}
                openState={showDialog}
                setOpenState={setShowDialog}
                alarmProp={alarmProfiles}
                deviceAction={addDevice}
            />




        </div>
    );
};

/*

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Device</DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Add</Button>
                </DialogActions>
            </Dialog>

*/